import React from "react";
import {
    DateRangePicker
} from "react-advance-jalaali-datepicker";
import moment from 'jalali-moment';

class RangePicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: this.props.start,
            endDate: this.props.end,
            ranges : {
                'امروز': [moment().locale('fa').format('YYYY/M/D'), moment().locale('fa').format('YYYY/M/D')],
                'دیروز': [moment().subtract(1, "days").locale('fa').format('YYYY/M/D'), moment().subtract(1, "days").locale('fa').format('YYYY/M/D')],
                "7 روز گذشته": [moment().subtract(6, "days").locale('fa').format('YYYY/M/D'), moment().locale('fa').format('YYYY/M/D')],
                "30 روز گذشته": [moment().subtract(29, "days").locale('fa').format('YYYY/M/D'), moment().locale('fa').format('YYYY/M/D')],
                "این ماه": [moment().locale('fa').startOf("month").format('YYYY/M/D'), moment().locale('fa').endOf("month").format('YYYY/M/D')],
                "ماه گدشنه": [
                    moment().locale('fa')
                        .subtract(1, "month")
                        .startOf("month").format('YYYY/M/D'),
                    moment().locale('fa')
                        .subtract(1, "month")
                        .endOf("month").format('YYYY/M/D')
                ],
                "امسال": [
                    moment().locale('fa').startOf("year").format('YYYY/M/D'),
                    moment().locale('fa').format('YYYY/M/D')
                ],
                "یک سال گدشته": [
                    moment().locale('fa')
                        .subtract(1, "year")
                        .startOf("year").format('YYYY/M/D'),
                    moment().locale('fa')
                        .subtract(1, "year")
                        .endOf("year").format('YYYY/M/D')
                ]
            },
            show_range:false,
        }
    }

    handleApply = (start, end) => {
        this.props.onApply({
            startDate: start,
            endDate: end
        })
    }

    changeStart = (unix, formatted) => {
        this.setState({startDate: formatted})
    }

    changeEnd = (unix, formatted) => {
        this.setState({endDate: formatted})
        this.handleApply(this.state.startDate, formatted);
        this.toggleShowRange();
    }

    handleItem =(item)=>{
        this.setState({endDate: item[1],startDate:item[0]})
        this.handleApply(item[0], item[1]);
        this.toggleShowRange();
    }

    toggleShowRange=()=>{
        this.setState({show_range:!this.state.show_range})
    }


    render() {
        return (

            <React.Fragment>
                <div>
                    <div className="rangeP">
                        <div onClick={()=>this.toggleShowRange()} className="title">
                            <div className="d-flex justify-content-between align-items-center">
                                <span>{this.state.startDate}</span>
                                <strong>تا</strong>
                                <span>{this.state.endDate}</span>
                            </div>
                        </div>
                        {
                            this.state.show_range?
                                <div className="ranges">
                                    {
                                        Object.keys(this.state.ranges).map((item,index)=>{
                                            return (
                                                <div className='it' onClick={()=>this.handleItem(this.state.ranges[item])}>
                                                    {item}
                                                </div>
                                            )
                                        })
                                    }
                                    <div className='custom'>

                                        <h5>انتخاب رنج تاریخ</h5>
                                        <DateRangePicker
                                            placeholderStart="تاریخ شروع"
                                            placeholderEnd="تاریخ پایان"
                                            format="jYYYY/jMM/jDD"
                                            onChangeStart={this.changeStart}
                                            onChangeEnd={this.changeEnd}
                                            idStart="rangePickerStart"
                                            idEnd="rangePickerEnd"
                                        />
                                    </div>
                                </div>
                                :""
                        }
                    </div>
                </div>


            </React.Fragment>
        );
    }
}

export default RangePicker;
