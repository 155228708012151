import React from 'react';
import Login from "./Login";
import Password from "./Password";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import Tfa from "./tfa/Tfa";
import {Route, Redirect, Switch} from "react-router-dom";
import AuthLayout from "./AuthLayout";
import MobileEmailVerify from "./MobileEmailVerify";
import {connect} from "react-redux";
import routes from "../../../routes";
import NoMatchRedirect from "../../NoMatchRedirect";
import swichUser from "./swichUser";

class AuthPages extends React.Component {

    render() {
        const user= this.props.user;

        return (
            user && user.is_admin ?
                    <>{this.props.children}</>
                :
                (
                    <AuthLayout>
                        <Switch>
                            <Route path={routes.switch} component={swichUser}/>
                            <Route path={routes.login_pass} component={Password}/>
                            <Route path={routes.login} component={Login}/>
                            <Route path={routes.two_factor_authenticator} component={Tfa}/>
                            <Route path={routes.register} component={Register}/>
                            <Route path={routes.forgot_password} component={ForgotPassword}/>
                            <Route path={routes.reset_password} component={ResetPassword}/>
                            <Route path={routes.logout} >
                                <Redirect to={routes.login}/>
                            </Route>
                            <Route exact path={routes.home}>
                                <Redirect to={routes.login}/>
                            </Route>
                            <Route component={NoMatchRedirect}/>
                        </Switch>
                    </AuthLayout>
                )

        );
    }
}


const mapStateToProps= state =>{
    return {
        user:state.user.info,
    }
}

export default connect(mapStateToProps)(AuthPages);
