import http from "./../../services/httpService";
import {updateUserStorage} from "../auth/authService";
import jwtDecode from "jwt-decode";
import {updateUser} from "../../actions/user";

export async function sendTokenCode(token) {
    return  await http.submit('post',  '/login/2fa/send-code',{
        token: token
    });
}

export async function sendRegisterMobileCode(mobile,type='login') {
    return  await http.submit('post',  '/login/2fa/send-code/register-mobile-active',{
        mobile: mobile,
        token_type:type
    });
}

export async function checkTokenCode(token) {
    return  await http.submit('post',  '/login/2fa/check-code',{
        token: token
    });
}

export async function checkCodeRegisterMobileActive(token,type='login') {
    return  await http.submit('post',  '/login/2fa/check-code/register',{
        token: token,
        token_type:type
    });
}

export async function checkVerifyTokenCode(type) {
    return  await http.submit('post',  '/verify/check-code',{
        type: type
    });
}

export async function sendVerifyCode(type) {
   return  await http.submit('post',  '/verify/send-code',{verifyType:type});
}

export async function confirmCode(type,code) {
   return  await http.submit('post',  '/verify/'+type+'/confirm',{code:code});
}

export async function referrals() {
    return  await http.post(  '/user/referrals/');
}

export async function getUser() {
    return  await http.post(  '/user/get/current-user');
}

export async function getInfo() {
    return  await http.post( '/user/get-info');
}


export async function latestOrders() {
    return  await http.post('/user/latest-orders');
}

export async function getUserVerificationText() {
    return  await http.post(  '/user/get-user-verification-text');
}

export async function getRewards() {
    return  await http.post(  '/user/get/rewards/info');
}

export async function getVipInfo() {
    return  await http.post(  '/user/get/vip/info');
}

export async function settlementRewardRequest() {
    return  await http.post(  '/user/get/rewards/request');
}

export async function getReferralUsers(currentPage) {
    return  await http.post(  '/user/get/referral/get-users',{page:currentPage});
}

export async function getReferralData(currentPage) {
    return  await http.post(  '/user/get/referral/get-data',{page:currentPage});
}


export function updateUserInStore(dispatch,user){
    updateUserStorage(user);
    user = jwtDecode(user);
    dispatch(updateUser(user))
}

export default {
  sendVerifyCode,
  confirmCode,
    getRewards,
    updateUserInStore,
    getReferralUsers,
    getReferralData,
    getVipInfo
};
