import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";

class DashboardLoading extends React.Component {

    render(){
        return (
            <React.Fragment>
                <SkeleteLoading>
                    <div className="row">
                        <div className="col-md-4"><Squre height={100}/></div>
                        <div className="col-md-4"><Squre height={100}/></div>
                        <div className="col-md-4"><Squre height={100}/></div>
                    </div>
                </SkeleteLoading>
            </React.Fragment>
        );
    }
}

export default DashboardLoading;
