import React from 'react';
import {connect} from "react-redux";
import 'react-circular-progressbar/dist/styles.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import '@coreui/chartjs/scss/coreui-chartjs.scss';
import moment from "jalali-moment";
import {updatePageTitle} from "../../actions/breadcrumbs";
import RangePicker from "../../components/RangePicker";
import http from "../../services/httpService";
import Emitter from "../../services/Emitter";
import {Redirect} from "react-router-dom";
import FinanceLoading from "../../components/loadings/FinanceLoading";
import {spilitNumber} from "../../services/helpers";
import OrderData from "../dashboard/superadmin/OrderData";
import Devices from "../cash/partials/Devices";
import CashData from "../dashboard/superadmin/CashData";

class CashesDashboard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingForm: true,
            category:{},
            all:{},
            to: moment().locale('fa').format('YYYY/M/D'),
            from: moment().locale('fa').format('YYYY/M/D'),
        }

        this.props.dispatch(updatePageTitle(
            'داشبورد سفارشات',
            {},
            this.handleDispatchHtml()
        ));
    }

    applyDate=(date)=>{
        this.setState({
            to: date.endDate,
            from: date.startDate,
        },()=>{
            this.reloadData()
        });


    }

    reloadData=()=>{
        this.setState({
            loadingForm:true
        });

        this.props.dispatch(updatePageTitle(
            'داشبورد سفارشات',
            {},
            this.handleDispatchHtml()
        ));

        this.getData();
    }

    handleDispatchHtml =()=>{
        return (
            <>
                <RangePicker start={this.state.from}  end={this.state.to} onApply={date=>this.applyDate(date)} />
            </>
        )
    }

    componentDidMount() {
        this.getData();
    }

    getData=()=>{
        http.post('cashes/get/data',{from:this.state.from,to:this.state.to})
            .then(response => {
                this.setState({
                    loadingForm: false,
                    category:(response.data.category?response.data.category:{}),
                    all:response.data.all,
                });

                Emitter.emit('reloadData');
            });
    }

    render() {
        const user = this.props.user;
        const rules = user.rules;
        const permissions = user.permissions;

        console.log(this.state.category,99)
        if(rules.includes('superadmin') || rules.includes('finance')|| permissions.includes('admin_report.finance')){
            return (
                this.state.loadingForm ?
                    <FinanceLoading/>
                    :
                    <React.Fragment>
                        <fieldset>
                            <legend>ورودی و خروجی ها</legend>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="card card-body dashboard-rr medium">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <div className="card-title">همه</div>
                                                <div className="balance">{spilitNumber(this.state.all.all.total)}<small className="ml-1 mr-1">تومان</small></div>
                                                <div className="count">{spilitNumber(this.state.all.all.count)}<small className="ml-1 mr-1">عدد</small></div>
                                            </div>
                                            <div>
                                                <svg  stroke="#888" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none"
                                                     stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                     className="feather feather-shopping-cart">
                                                    <circle cx="9" cy="21" r="1"></circle>
                                                    <circle cx="20" cy="21" r="1"></circle>
                                                    <path
                                                        d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                                                </svg>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div  className="card card-body dashboard-rr medium">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <div className="card-title">انجام شده</div>
                                                <div className="balance">{spilitNumber(this.state.all.done.total)}<small className="ml-1 mr-1">تومان</small></div>
                                                <div className="count">{spilitNumber(this.state.all.done.count)}<small className="ml-1 mr-1">عدد</small></div>                                        </div>
                                            <div>
                                                <svg  stroke="#00ab15" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                      viewBox="0 0 24 24" fill="none"
                                                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                      className="feather feather-shopping-cart">
                                                    <circle cx="9" cy="21" r="1"></circle>
                                                    <circle cx="20" cy="21" r="1"></circle>
                                                    <path
                                                        d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                                                </svg>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card card-body dashboard-rr medium">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <div className="card-title">کنسل شده</div>
                                                <div className="balance">{spilitNumber(this.state.all.cancel.total)}<small className="ml-1 mr-1">تومان</small></div>
                                                <div className="count">{spilitNumber(this.state.all.cancel.count)}<small className="ml-1 mr-1">عدد</small></div>                                        </div>
                                            <div>

                                                <svg  stroke="#d80000" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                      viewBox="0 0 24 24" fill="none"
                                                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                      className="feather feather-shopping-cart">
                                                    <circle cx="9" cy="21" r="1"></circle>
                                                    <circle cx="20" cy="21" r="1"></circle>
                                                    <path
                                                        d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                                                </svg>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card card-body dashboard-rr medium">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <div className="card-title">معلق شده</div>
                                                <div className="balance">{spilitNumber(this.state.all.suspend.total)}<small className="ml-1 mr-1">تومان</small></div>
                                                <div className="count">{spilitNumber(this.state.all.suspend.count)}<small className="ml-1 mr-1">عدد</small></div>                                        </div>
                                            <div>

                                                <svg  stroke="#d89400" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                      viewBox="0 0 24 24" fill="none"
                                                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                      className="feather feather-shopping-cart">
                                                    <circle cx="9" cy="21" r="1"></circle>
                                                    <circle cx="20" cy="21" r="1"></circle>
                                                    <path
                                                        d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                                                </svg>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <CashData f={this.state.from} t={this.state.to}/>

                        {
                            this.state.category.length >0?
                                <div className={'card card-body '}>
                                    <div className={'card-title'}>گزارش بر اساس دسته بندی</div>
                                    <div className="table-responsive mt-3">
                                        <table className="table">
                                            <tr>
                                                <th>دسته سرویس</th>
                                                <th>تعداد</th>
                                                <th>میزان </th>
                                                <th className='d-none d-md-flex'>وضعیت </th>
                                            </tr>

                                            {
                                                this.state.category.map(order=> (
                                                    <tr>
                                                        <th>{order.type}</th>
                                                        <th>{spilitNumber(order.count)} <small className="ml-1 mr-1">عدد</small></th>
                                                        <th>{spilitNumber(order.total)} <small className="ml-1 mr-1">تومان</small></th>
                                                        <th className='d-none d-md-flex'>انجام شده </th>
                                                    </tr>
                                                ))
                                            }


                                        </table>
                                    </div>

                                </div>

                                :""
                        }

                        <Devices f={this.state.from} t={this.state.to}/>



                    </React.Fragment>
            );
        } else {
            return <Redirect to={'/'}/>;
        }
    }
}

const mapStateToComponent = state => {
    return {
        user: state.user.info,
        app: state.app,
    }
}

export default connect(mapStateToComponent)(CashesDashboard);
