import React from 'react';
import http from "./../../../services/httpService";
import 'react-circular-progressbar/dist/styles.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import '@coreui/chartjs/scss/coreui-chartjs.scss';
import Emitter from '../../../services/Emitter';
import Squre from "../../../components/skelete/Squre";
import SkeleteLoading from "../../../components/SkeleteLoading";
import {spilitNumber} from "../../../services/helpers";
import {CChart} from "@coreui/react-chartjs";

class TransactionsCharts extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingForm: true,
            to: this.props.t,
            from: this.props.f,
            charts: {},
        }

    }

    getCharts=()=>{
        http.post('finance/get/transactions/chart',{from:this.state.from,to:this.state.to})
            .then(response => {
                this.setState({
                    loadingForm: false,
                    charts:response.data.transactionsChart,
                });
            });
    }


    componentDidMount() {
        Emitter.on('reloadData', () => this.getCharts());
    }

    componentWillUnmount() {
        Emitter.off('reloadData');
    }

    render() {

        return (
            this.state.loadingForm ?
                <SkeleteLoading>
                    <div className=" mt-4">
                        <Squre height={300}/>
                    </div>
                </SkeleteLoading>
                :
                <React.Fragment>
                    <div className="card card-body dashboard-rr chart-data mt-4">
                        <div className="card-title">تراکنش ها</div>
                        {this.state.charts?
                            <CChart
                                height={90}
                                type="line"
                                data={{
                                    labels: this.state.charts.all.label,
                                    datasets: [
                                        {
                                            label: this.state.charts.all.title,
                                            backgroundColor: "rgba(220, 220, 220, 0.2)",
                                            borderColor:  "rgb(23, 165, 2)",
                                            pointBackgroundColor: "rgb(23, 165, 2)",
                                            data: this.state.charts.all.data
                                        },
                                        {
                                            label: this.state.charts.gateway.title,
                                            backgroundColor: "rgba(220, 220, 220, 0.2)",
                                            borderColor: "rgba(0,100,255,0.65)",
                                            pointBackgroundColor: "rgb(0, 100, 255)",
                                            data: this.state.charts.gateway.data
                                        },
                                        {
                                            label: this.state.charts.credit.title,
                                            backgroundColor: "rgba(220, 220, 220, 0.2)",
                                            borderColor: "rgba(255,158,0,0.5)",
                                            pointBackgroundColor: "rgb(255, 158, 0)",
                                            pointBorderColor: "#fff",
                                            data: this.state.charts.credit.data
                                        },
                                        {
                                            label: this.state.charts.withdraw.title,
                                            backgroundColor: "rgba(220, 220, 220, 0.2)",
                                            borderColor: "rgba(255,0,0,0.54)",
                                            pointBackgroundColor: "rgb(255, 0, 0)",
                                            pointBorderColor: "#fff",
                                            data: this.state.charts.withdraw.data
                                        }
                                    ],
                                }}
                            />
                            :''}
                    </div>
                </React.Fragment>


        )
    }

}

export default TransactionsCharts;
