import React from 'react';
import {connect} from "react-redux";
import 'react-circular-progressbar/dist/styles.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import '@coreui/chartjs/scss/coreui-chartjs.scss';
import moment from "jalali-moment";
import {updatePageTitle} from "../../actions/breadcrumbs";
import RangePicker from "../../components/RangePicker";
import http from "../../services/httpService";
import Emitter from "../../services/Emitter";
import {Redirect} from "react-router-dom";
import FinanceLoading from "../../components/loadings/FinanceLoading";
import {spilitNumber} from "../../services/helpers";
import OrderItemProfit from "./partials/OrderItemProfit";
import Pagination from "../../ubold/commons/pagination";
import SkeleteLoading from "../../components/SkeleteLoading";
import Squre from "../../components/skelete/Squre";
import {CChart} from "@coreui/react-chartjs";


class OrderCooperation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingForm: true,
            orders:{},
            to: moment().locale('fa').format('YYYY/M/D'),
            from: moment().locale('fa').format('YYYY/M/D'),
        }

        this.props.dispatch(updatePageTitle(
            'گزارش همکاران و کاربران',
            {},
            this.handleDispatchHtml()
        ));
    }

    applyDate=(date)=>{
        this.setState({
            to: date.endDate,
            from: date.startDate,
        },()=>{
            this.reloadData()
        });


    }

    reloadData=()=>{
        this.setState({
            loadingForm:true
        });

        this.props.dispatch(updatePageTitle(
            'گزارش همکاران و کاربران',
            {},
            this.handleDispatchHtml()
        ));

        this.getData();
    }

    handleDispatchHtml =()=>{
        return (
            <>
                <RangePicker start={this.state.from}  end={this.state.to} onApply={date=>this.applyDate(date)} />
            </>
        )
    }

    componentDidMount() {
        this.getData();
    }



    getData=()=>{
        http.post('orders/get/cooperation',{from:this.state.from,to:this.state.to})
            .then(response => {
                this.setState({
                    loadingForm: false,
                    orders:response.data.cooperationsChart,
                    sum:response.data.sum,
                    count:response.data.count,
                    profit:response.data.profit,
                });

                Emitter.emit('reloadData');
            });
    }

    render() {
        const user = this.props.user;
        const rules = user.rules;
        const permissions = user.permissions;

        if(rules.includes('superadmin') || permissions.includes('admin_report.orders')){
            return (
                this.state.loadingForm ?
                    <SkeleteLoading>
                        <fieldset >
                            <legend>بیشترین و کمترین</legend>
                            <div className="row">
                                <div className="col-md-4 mb-3"><Squre height={100}/></div>
                                <div className="col-md-4 mb-3"><Squre height={100}/></div>
                                <div className="col-md-4 mb-3"><Squre height={100}/></div>

                            </div>
                        </fieldset>
                        <div className=" mt-3"><Squre height={300}/></div>

                    </SkeleteLoading>
                    :
                    <React.Fragment>
                        <fieldset className={'mb-4'}>
                            <legend>تفکیک کاربر و همکار</legend>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card card-body dashboard-rr medium mb-4">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <div className="card-title">همه </div>
                                                <div className="balance">{spilitNumber(this.state.sum.all)}<small className="ml-1 mr-1">تومان</small></div>
                                                <div className="count "> تعداد :
                                                    {spilitNumber(this.state.count.all)}<small className="ml-1 mr-1">عدد</small>
                                                </div>
                                                <div className={this.state.profit.all>=0?'text-success':'text-danger'}>سود
                                                    : <strong>{spilitNumber(this.state.profit.all)}</strong>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div  className="card card-body dashboard-rr medium">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <div className="card-title">کاربران سایت</div>
                                                <div className="balance">{spilitNumber(this.state.sum.users)}<small className="ml-1 mr-1">تومان</small></div>
                                                <div className="count "> تعداد :
                                                    {spilitNumber(this.state.count.users)}<small className="ml-1 mr-1">عدد</small>
                                                </div>
                                                <div className={this.state.profit.users>=0?'text-success':'text-danger'}>سود
                                                    : <strong>{spilitNumber(this.state.profit.users)}</strong>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div  className="card card-body dashboard-rr medium">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <div className="card-title">همکاران</div>
                                                <div className="balance">{spilitNumber(this.state.sum.cooperations)}<small className="ml-1 mr-1">تومان</small></div>
                                                <div className="count "> تعداد :
                                                    {spilitNumber(this.state.count.cooperations)}<small className="ml-1 mr-1">عدد</small>
                                                </div>
                                                <div className={this.state.profit.cooperations>=0?'text-success':'text-danger'}>سود
                                                    : <strong>{spilitNumber(this.state.profit.cooperations)}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>



                        <div className="card card-body">
                            <CChart
                                height={90}
                                type="line"
                                scaleOverride = "true"
                                scaleSteps = "10"
                                scaleStepWidth = "50"
                                scaleStartValue = "0"
                            data={{
                                    labels: this.state.orders.all.label,
                                    datasets: [
                                        {
                                            label: this.state.orders.all.title,
                                            backgroundColor: "rgba(220, 220, 220, 0.2)",
                                            borderColor:  "rgb(23, 165, 2)",
                                            pointBackgroundColor: "rgb(23, 165, 2)",
                                            data: this.state.orders.all.data
                                        },
                                        {
                                            label: this.state.orders.users.title,
                                            backgroundColor: "rgba(220, 220, 220, 0.2)",
                                            borderColor:  "rgb(2,78,165)",
                                            pointBackgroundColor: "rgba(2,35,165,0.53)",
                                            data: this.state.orders.users.data
                                        },
                                        {
                                            label: this.state.orders.cooperations.title,
                                            backgroundColor: "rgba(220, 220, 220, 0.2)",
                                            borderColor:  "rgb(252,16,213)",
                                            pointBackgroundColor: "rgba(255,25,127,0.53)",
                                            data: this.state.orders.cooperations.data
                                        }
                                    ],
                                }}
                            />
                        </div>

                    </React.Fragment>
            );
        } else {
            return <Redirect to={'/'}/>;
        }
    }
}

const mapStateToComponent = state => {
    return {
        user: state.user.info,
        app: state.app,
    }
}

export default connect(mapStateToComponent)(OrderCooperation);
