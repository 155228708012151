import React from 'react';
import http from "./../../../services/httpService";
import 'react-circular-progressbar/dist/styles.css';
import {CChart} from "@coreui/react-chartjs";

import "bootstrap-daterangepicker/daterangepicker.css";
import '@coreui/chartjs/scss/coreui-chartjs.scss';
import Emitter from '../../../services/Emitter';
import Squre from "../../../components/skelete/Squre";
import SkeleteLoading from "../../../components/SkeleteLoading";


class Userdata extends React.Component {

    constructor(props) {
        super(props);

        console.log(this.props)
        this.state = {
            loadingForm: true,
            to: this.props.t,
            from: this.props.f,
            userChart:false,
        }

    }

    getUserData=()=>{
        http.post('dashboard/get/user-data',{from:this.state.from,to:this.state.to})
            .then(response => {
                this.setState({
                    loadingForm: false,
                    userChart:response.data.userChart,
                });
            });
    }


    componentDidMount() {
        Emitter.on('reloadData', () => this.getUserData());
    }

    componentWillUnmount() {
        Emitter.off('reloadData');
    }

    render() {

        return (
            this.state.loadingForm ?
                <SkeleteLoading>
                    <div className=" mt-4">
                        <Squre height={300}/>
                    </div>
                </SkeleteLoading>
                :
                <React.Fragment>
                    <div  className="card card-body dashboard-rr chart-data  mt-4" >
                        <div className="card-title">ثبت نام</div>
                        {this.state.userChart?
                            <CChart
                                height={90}
                                type="line"

                                data={{
                                    labels: this.state.userChart.all.label,
                                    datasets: [
                                        {
                                            label: this.state.userChart.all.title,
                                            backgroundColor: "rgba(31,141,15,0.87)",
                                            borderColor: "rgba(8,182,62,0.51)",
                                            pointBackgroundColor: "rgb(8,182,62)",
                                            pointBorderColor: "#fff",
                                            data: this.state.userChart.all.data
                                        },
                                        {
                                            label: this.state.userChart.no_verify.title,
                                            backgroundColor: "rgba(239,37,37,0.06)",
                                            borderColor: "rgba(182,8,40,0.51)",
                                            pointBackgroundColor: "rgb(182,8,54)",
                                            pointBorderColor: "#fff",
                                            data: this.state.userChart.no_verify.data
                                        },
                                    ],
                                }}
                            />
                            :''}
                    </div>
                </React.Fragment>

        )
    }

}

export default Userdata;
