const base_url = '';

const routes = {
    home: base_url + '/',
    login: base_url + '/login',
    switchLogout: base_url + '/switch/go/logout',
    switch: base_url + '/switch/:token',
    logout: base_url + '/logout',
    register: base_url + '/register',
    login_pass: base_url + '/login/password',
    two_factor_authenticator: base_url + '/tow-factor-authentication',
    forgot_password: base_url + '/forgot-password',
    reset_password: base_url + '/password/reset/:token',

    finance_dashboard: base_url+ '/finance/dashboard',

    orders_dashboard: base_url+ '/orders/dashboard',
    orders_type: base_url+ '/orders/type',
    orders_type_id: base_url+ '/orders/type/:type',
    orders_category: base_url+ '/orders/category',
    orders_sort_profit: base_url+ '/orders/sort_profit',
    orders_service: base_url+ '/orders/service',
    orders_service_id: base_url+ '/orders/service/:id',
    orders_cooperation: base_url+ '/orders/cooperation',
    orders_sale_profit: base_url+ '/orders/sales-profit',

    cashes_dashboard: base_url+ '/cashes/dashboard',
    cashes_category: base_url+ '/cashes/category',
    cashes_service: base_url+ '/cashes/service',
    cashes_service_id: base_url+ '/cashes/service/:id',

    user_dashboard: base_url+ '/user/dashboard',


}


export default routes;