import React from 'react';
import Form from "./../../../../ubold/commons/form";
import Joi from "joi-browser";
import auth, {validateRegisterMobileRegisterCode} from "../../authService";
import {Link, Redirect} from "react-router-dom";
import $ from "jquery";
import loading from "../../../../assets/images/loading.svg";
import {
    checkCodeRegisterMobileActive,
    sendRegisterMobileCode
} from "../../../user/userService";
import {secondsToHms} from "../../../../services/helpers";
import routes from "../../../../routes";
import lock from "../../../../assets/images/svg-icons/tfa.svg";
import OtpInput from 'react-otp-input';
import Recaptcha from "react-google-invisible-recaptcha";
import {siteKey} from "../../../../config.json";

var timer = null;

class RegisterMobileActive extends Form {

    constructor(props) {
        super(props);


        const search = window.location.search;
        var token = new URLSearchParams(search).get('token');

        if (this.props.token)
            token = this.props.token;

        this.state = {
            data: {
                code: "",
                token: token,
                token_type: this.props.tokentype ? this.props.tokentype : 'login',
                ntu: true
            },
            errors: {},
            loading: false,
            loadingToken: false,
            c: 0,
            t: null,
            activeSendCodeButton: false,
            token: token,
            token_type: this.props.tokentype ? this.props.tokentype : 'login',
            mobile: ''
        };

        this.schema = {
            code: Joi.string()
                .required()
                .label("کد احراز هویت")
        };
    }

    componentDidMount = async () => {

        const res = await checkCodeRegisterMobileActive(this.state.token, this.state.token_type);
        this.setState({
            mobile: res.sec.mobile,
            c: parseInt(res.sec.seconds),
        });

        if (parseInt(res.sec.seconds) > 0) {
            this.startCount();
            this.setState({activeSendCodeButton: true});
        }
    }

    showSeconds = () => {
        var cm = this.state.c;
        if (cm === 0) {
            this.stopCount();
        } else {
            cm = cm - 1;
            this.setState({c: cm, t: secondsToHms(cm)});
        }
    };

    startCount = () => {
        timer = setInterval(this.showSeconds, 1000);
    }

    stopCount = () => {
        clearInterval(timer);
        this.setState({c: 0, t: null});
        this.setState({activeSendCodeButton: false});
    }

    sendToken = async () => {

        this.setState({activeSendCodeButton: true,loadingToken:true});
        const res = await sendRegisterMobileCode(this.state.mobile, this.state.token_type);
        const {data} = this.state;
        data['token'] = res.token;

        this.setState({
            c: parseInt(res.seconds),
            token: res.token,
            data,
            loadingToken:false
        });

        window.history.pushState("state", "title", this.props.redirecturl + "?token=" + res.token);

        if (this.props.callbacktoken)
            this.props.callbacktoken(res.token);

        this.startCount();

    }

    doSubmit = async () => {
        this.recaptcha.execute().then(res => {
            this.toSubmit(res);
        }).catch(res => {
            window.location = window.location.href;
        })
    };

    toSubmit = async (token) => {
        this.setState({
            loading: true
        });

        try {
            const {data} = this.state;
            const res = await auth.validateRegisterMobileRegisterCode(data);

            if (res.mobile) {
                this.props.callback(res);
            }

        } catch (ex) {
            this.setState({
                loading: false
            });
            this.recaptcha.reset();

        }
    }



    onResolved = () => {
        const {data} = this.state;
        data['g-recaptcha-response'] = this.recaptcha.getResponse();
        this.setState({data});
    }

    handleChangeOtp = (otp) => {
        const data = this.state.data;
        data['code'] = otp;


        this.setState({data});

        if (otp.length >= 6) {
            this.doSubmit();
        }

    }

    render() {

        if (auth.getCurrentUser()) return <Redirect to="/"/>;
        return (
            <React.Fragment>
                <div className="">
                    <div className="img-box">
                        <img className='d-block m-auto logo' height={200} width={200} alt={'cafearz'}
                             src={lock}/>
                        <div className="btt"></div>
                    </div>
                    <div className="auth-title-box">
                        <h1 className="auth-title">تایید شماره همراه</h1>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body a ">
                        <div className="auth-title-box mb-4">
                            <div className='help text-center mb-2'>کد تایید برای شماره همراه <strong
                                className='d-inline-block ml-1 mr-1'>{this.state.mobile}</strong> را وارد کنید
                            </div>
                            <div className='help text-center'>آیا شماره موبایل اشتباه است؟
                                <Link href='#' to={routes.login} className="text-t mr-2 text-primary">ویرایش
                                    کنید</Link>
                            </div>
                        </div>
                        <form method="POST" onSubmit={this.handleSubmit}
                              className="form-horizontal dialog-form embed">

                            <div className="form-group-c">
                                <div
                                    className={'otp-code ' + ((this.state.errors && this.state.errors['code']) && 'error')}>
                                    <OtpInput
                                        value={this.state.data.code}
                                        onChange={this.handleChangeOtp}
                                        numInputs={6}
                                        separator={''}
                                        shouldAutoFocus={true}
                                        isInputNum={true}
                                        hasErrored={true}
                                    />
                                </div>
                            </div>

                            <div className="text-center mb-3 mt-4">
                                <button style={{display: 'inline-block'}} disabled={this.state.activeSendCodeButton}
                                        type="button"
                                        onClick={this.sendToken} id="sendCode"
                                        className="send-code m-auto text-t text-center m-auto">
                                    {this.state.loadingToken ?
                                        <div className='d-inline-block'>
                                            <img  height='20' className='ml-1 ' src={loading} />
                                            در حال ارسال ...
                                        </div>
                                        :"ارسال مجدد کد فعال سازی"}

                                    {
                                        this.state.c > 0 ?
                                            <span className='mr-2' id="ccc">(<span
                                                id="counter">{this.state.t}</span>)</span>
                                            : ""
                                    }


                                </button>
                            </div>

                            <div className="form-group">
                                {this.renderButton("تایید ", 'btn-block ')}
                            </div>
                            <Recaptcha
                                ref={ref => this.recaptcha = ref}
                                sitekey={siteKey}
                                onResolved={this.onResolved}/>

                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

export default RegisterMobileActive;
