import React from 'react';
import {connect} from 'react-redux';
import {Route, Switch, Redirect, withRouter, Link} from "react-router-dom";
import NoMatch from "./NoMatch";
import Dashboard from "./Dashboard";
import Logout from "./auth/components/logout";
import axios from "axios";
import jwtDecode from "jwt-decode";
import {updateUser} from "../actions/user";
import {ToastContainer} from "react-toastify";
import ReactTooltip from 'react-tooltip';
import $ from "jquery";
import {
    hideAppLoader,
    setAppRedirect,
    setCryptoActiveExchangers,
    setCryptoBaseExchanger,
    setEmailPhoneApp,  setVersion
} from "../actions/app";
import loading from "../assets/images/loading.svg";
import Breadcrumbs from "../ubold/components/layouts/Breadcrumbs";
import routes from "../routes";
import Sidebar from "../ubold/components/layouts/Sidebar";
import {updateUserStorage} from "./auth/authService";
import PageLoading from "../ubold/components/layouts/PageLoading";
import {changeTheme} from "../actions/app";
import swichUserLogout from "./auth/components/swichUserLogout";
import swichUser from "./auth/components/swichUser";
import LocationsHandler  from "../components/LocationsHandler";
import FinanceDashboard from "./finance/FinanceDashboard";
import OrderDashboard from "./orders/OrderDashboard";
import UsersDashboard from "./users/UsersDashboard";
import OrderSortProfit from "./orders/OrderSortProfit";
import OrderCategory from "./orders/OrderCategory";
import OrderService from "./orders/OrderService";
import OrderTypeShow from "./orders/OrderTypeShow";
import OrderCooperation from "./orders/OrderCooperation";
import CashesCategory from "./cash/CashesCategory";
import CashesDashboard from "./cash/CashesDashboard";
import CashesService from "./cash/CashesService";
import OrderProfitAndSell from "./orders/OrderProfitAndSell";

const feather = require('feather-icons')

class Panel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pageLoading: true,
            is_checked_theme: false,
            redirect: false,
            show_sidebar:this.props.show_sidebar
        };

    }

    componentDidMount() {

        axios.post('/user/get')
            .then(response => {
                updateUserStorage(response.data.user);
                let user = jwtDecode(response.data.user);
                this.props.dispatch(updateUser(user))
                this.props.dispatch(setEmailPhoneApp(user.app.email, user.app.phone));
                this.props.dispatch(hideAppLoader());
                this.props.dispatch(setCryptoBaseExchanger(user.app.crypto_base_exchanger));
                this.props.dispatch(setCryptoActiveExchangers(user.app.crypto_active_exchangers));
                this.props.dispatch(setVersion(user.app.version));
                this.setState({
                    pageLoading: false,
                });
            });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        $(".logout").removeClass('btn').removeClass('btn-success');
    }

    handleChangeTheme = (e) => {
        this.setState({
            is_checked_theme: !this.state.is_checked_theme
        });

        const theme = (e.target.checked ? 1 : 0)
        this.props.dispatch(changeTheme(theme));
        localStorage.removeItem('dark_theme');
        localStorage.setItem('dark_theme', theme);
    }

    handleShowEvent = (type) =>{
        this.setState({show_event:type});
    }

    toggleSideMenu = (type) =>{
        this.setState({show_sidebar:type});
    }

    syncHandler=()=>{
        this.toggleSideMenu(false)
        this.handleShowEvent(false)
    }

    render() {

        if (this.props.app.redirect) {
            let uri = this.props.app.redirect;
            this.props.dispatch(setAppRedirect(null));
            return <Redirect to={uri}/>
        }

        const {user} = this.props;

        const rules = user.rules;
        const permissions = user.permissions;

        return (

            this.state.pageLoading ?
                <PageLoading/>
                :
                    <React.Fragment>

                        <LocationsHandler sync={()=>this.syncHandler()} />


                        <ToastContainer/>
                        <div className='full-loading justify-content-center align-items-center'
                             style={{display: this.props.app.full_loading ? "flex" : "none"}}>
                            <img alt={'loading'} src={loading} height={150}/>
                        </div>

                        {
                            this.state.show_sidebar?<div onClick={()=>this.toggleSideMenu(false)} className='togglemenu-over'></div>:""
                        }

                        <div className={"n-app-sidebar "+(this.state.show_sidebar?"show":"")}>
                            <Sidebar darktheme={this.props.app.dark_theme} toggleSideMenu={(t)=>this.toggleSideMenu(t)} user={user}/>
                        </div>
                        <main id="panel-wrapper">

                            <div className="n-app-main">

                                <div className="app-container">
                                    <div className="top-header d-flex justify-content-between">
                                        <div>
                                            <strong className='d-none d-md-block '> پنل گزارش</strong>
                                        </div>

                                        <div className='d-flex align-items-center' >
                                            <button onClick={()=>this.toggleSideMenu(true)} className={'toggle-menu d-block d-lg-none'}>
                                                <i dangerouslySetInnerHTML={{__html: feather.icons['menu'].toSvg()}}/>
                                            </button>

                                            <Link to='/'  className="events ml-2">
                                                <i dangerouslySetInnerHTML={{__html: feather.icons['home'].toSvg()}}/>
                                            </Link>
                                        </div>


                                    </div>

                                    <section className="main">

                                        <Breadcrumbs/>

                                        <Switch>
                                            <Route path={routes.login}><Redirect to='/'/></Route>
                                            <Route path={routes.login_pass}><Redirect to='/'/></Route>
                                            <Route path={routes.register}><Redirect to='/'/></Route>
                                            <Route path={routes.forgot_password}><Redirect to='/'/></Route>
                                            <Route path={routes.reset_password}><Redirect to='/'/></Route>
                                            <Route path={routes.two_factor_authenticator}><Redirect to='/'/></Route>

                                            <Route exact path={routes.home} component={Dashboard}/>
                                            <Route  path={routes.finance_dashboard} component={FinanceDashboard}/>
                                            <Route  path={routes.orders_dashboard} component={OrderDashboard}/>
                                            <Route  path={routes.orders_category} component={OrderCategory}/>
                                            <Route  path={routes.orders_type_id} component={OrderTypeShow}/>
                                            <Route  path={routes.orders_sort_profit} component={OrderSortProfit}/>
                                            <Route  path={routes.orders_service_id} component={OrderService}/>
                                            <Route  path={routes.orders_cooperation} component={OrderCooperation}/>
                                            <Route  path={routes.orders_sale_profit} component={OrderProfitAndSell}/>

                                            <Route  path={routes.cashes_dashboard} component={CashesDashboard}/>
                                            <Route  path={routes.cashes_category} component={CashesCategory}/>
                                            <Route  path={routes.cashes_service_id} component={CashesService}/>

                                            <Route  path={routes.user_dashboard} component={UsersDashboard}/>

                                            <Route path={routes.logout} component={Logout}/>
                                            <Route path={routes.switchLogout} component={swichUserLogout}/>

                                            <Route path={routes.switch} component={swichUser}/>

                                            <Route component={NoMatch}/>
                                        </Switch>

<br/>
                                    </section>

                                </div>
                            </div>
                        </main>

                        <ReactTooltip/>
                    </React.Fragment>

        );
    }
}

const mapStateToProps = (state) => {

    return {
        user: state.user.info,
        app: state.app,
    }
}

export default connect(mapStateToProps)(withRouter(Panel));



