import React from 'react';
import {connect} from "react-redux";
import 'react-circular-progressbar/dist/styles.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import '@coreui/chartjs/scss/coreui-chartjs.scss';
import moment from "jalali-moment";
import {updatePageTitle} from "../../actions/breadcrumbs";
import RangePicker from "../../components/RangePicker";
import http from "../../services/httpService";
import Emitter from "../../services/Emitter";
import {Link, Redirect} from "react-router-dom";
import {spilitNumber} from "../../services/helpers";
import SkeleteLoading from "../../components/SkeleteLoading";
import Squre from "../../components/skelete/Squre";
import routes from "../../routes";

class CashesCategory extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingForm: true,
            items:{},
            to: moment().locale('fa').format('YYYY/M/D'),
            from: moment().locale('fa').format('YYYY/M/D'),
        }

        this.props.dispatch(updatePageTitle(
            'گزارش  با  نوع سرویس',
            {},
            this.handleDispatchHtml()
        ));
    }

    applyDate=(date)=>{
        this.setState({
            to: date.endDate,
            from: date.startDate,
        },()=>{
            this.reloadData()
        });


    }

    reloadData=()=>{
        this.setState({
            loadingForm:true
        });

        this.props.dispatch(updatePageTitle(
            'گزارش  با  نوع سرویس',
            {},
            this.handleDispatchHtml()
        ));

        this.getData();
    }

    handleDispatchHtml =()=>{
        return (
            <>
                <RangePicker start={this.state.from}  end={this.state.to} onApply={date=>this.applyDate(date)} />
            </>
        )
    }

    componentDidMount() {
        this.getData();
    }

    getData=()=>{
        http.post('cashes/get/category',{from:this.state.from,to:this.state.to})
            .then(response => {
                this.setState({
                    loadingForm: false,
                    items:response.data.data,
                });

                Emitter.emit('reloadData');
            });
    }

    render() {
        const user = this.props.user;
        const rules = user.rules;
        const permissions = user.permissions;

        if(rules.includes('superadmin') || permissions.includes('admin_report.cashes')){
            return (
                this.state.loadingForm ?
                    <SkeleteLoading>
                        <Squre  height={200}/>
                    </SkeleteLoading>
                    :
                    <React.Fragment>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>سفارش</th>
                                        <th>تعداد</th>
                                        <th>میزان</th>
                                        <th>میانگین نرخ</th>
                                        <th>مجموع</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.items.length >0 ? this.state.items.map(d=>(

                                                <tr>
                                                    <td><Link to={routes.cashes_service +'/'+d.service}><strong> {d.name} </strong></Link></td>
                                                    <td><strong>{d.count}</strong></td>
                                                    <td>{Math.round(d.total_amount,8)}</td>
                                                    <td>{spilitNumber(Math.round(d.avg_currency_price))}</td>
                                                    <td><strong>{spilitNumber(Math.round(d.sum_total))}</strong></td>

                                                </tr>
                                            )) :
                                            <tr>
                                                <td colSpan="20">هیچ سفارشی در این بازه وجود ندارد</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>

                    </React.Fragment>
            );
        } else {
            return <Redirect to={'/'}/>;
        }
    }
}

const mapStateToComponent = state => {
    return {
        user: state.user.info,
        app: state.app,
    }
}

export default connect(mapStateToComponent)(CashesCategory);
