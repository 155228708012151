import React from 'react';
import {connect} from "react-redux";
import 'react-circular-progressbar/dist/styles.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import '@coreui/chartjs/scss/coreui-chartjs.scss';
import moment from "jalali-moment";
import {updatePageTitle} from "../../actions/breadcrumbs";
import RangePicker from "../../components/RangePicker";
import http from "../../services/httpService";
import Emitter from "../../services/Emitter";
import { Redirect} from "react-router-dom";
import SkeleteLoading from "../../components/SkeleteLoading";
import Squre from "../../components/skelete/Squre";
import {spilitNumber} from "../../services/helpers";
import CashesServiceCharts from "./partials/CashesServiceCharts";
import CashesServiceDetails from "./partials/CashesServiceDetails";

class CashesService extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id:this.props.match.params.id,
            loadingForm: true,
            chart:null,
            service:{},
            data:{},
            details:{},
            to: moment().locale('fa').format('YYYY/M/D'),
            from: moment().locale('fa').format('YYYY/M/D'),
        }

        this.props.dispatch(updatePageTitle(
            this.state.service.title,
            {},
            this.handleDispatchHtml()
        ));
    }

    applyDate=(date)=>{
        this.setState({
            to: date.endDate,
            from: date.startDate,
        },()=>{
            this.reloadData()
        });
    }

    reloadData=()=>{
        this.setState({
            loadingForm:true
        });

        this.props.dispatch(updatePageTitle(
            this.state.service.title,
            {},
            this.handleDispatchHtml()
        ));

        this.getData();
    }

    handleDispatchHtml =()=>{
        return (
            <>
                <RangePicker start={this.state.from}  end={this.state.to} onApply={date=>this.applyDate(date)} />
            </>
        )
    }

    componentDidMount() {
        this.getData();
    }

    getData=()=>{
        http.post('cashes/get/service/'+this.state.id,{from:this.state.from,to:this.state.to})
            .then(response => {

                this.setState({
                    loadingForm: false,
                    chart:response.data.chart,
                    service:response.data.service,
                    data:response.data.data,
                    details:response.data.details,
                });

                this.props.dispatch(updatePageTitle(
                    response.data.service.title,
                    {},
                    this.handleDispatchHtml()
                ));

                Emitter.emit('reloadData');
            });
    }

    render() {
        const user = this.props.user;
        const rules = user.rules;
        const permissions = user.permissions;

        if(rules.includes('superadmin') || permissions.includes('admin_report.cashes')){
            return (
                this.state.loadingForm ?
                    <SkeleteLoading>
                        <Squre  height={200}/>
                    </SkeleteLoading>
                    :
                    <React.Fragment>
                        <fieldset className={'mb-3'}>
                            <legend>آمار سفارشات انجام شده</legend>
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="card card-body dashboard-rr medium">
                                        <div className="d-flex flex-column justify-content-between align-items-center">
                                            <div className="card-title">همه</div>
                                            <div className="balance">{spilitNumber(this.state.data.all)}<small className="ml-1 mr-1">نفر</small></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div  className="card card-body dashboard-rr medium">
                                        <div className="d-flex flex-column justify-content-between align-items-center">
                                            <div className="card-title">امروز</div>
                                            <div className="balance">{spilitNumber(this.state.data.orders_count_today)}<small className="ml-1 mr-1">عدد</small></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div  className="card card-body dashboard-rr medium">
                                        <div className="d-flex flex-column justify-content-between align-items-center">
                                            <div className="card-title">دیروز</div>
                                            <div className="balance">{spilitNumber(this.state.data.orders_count_yesterday)}<small className="ml-1 mr-1">عدد</small></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="card card-body dashboard-rr medium">
                                        <div className="d-flex flex-column justify-content-between align-items-center">
                                            <div className="card-title">این هفته</div>
                                            <div className="balance">{spilitNumber(this.state.data.orders_count_week)}<small className="ml-1 mr-1">عدد</small></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="card card-body dashboard-rr medium">
                                        <div className="d-flex flex-column justify-content-between align-items-center">
                                            <div className="card-title">این ماه </div>
                                            <div className="balance">{spilitNumber(this.state.data.orders_count_month)}<small className="ml-1 mr-1">عدد</small></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="card card-body dashboard-rr medium">
                                        <div className="d-flex flex-column justify-content-between align-items-center">
                                            <div className="card-title">ماه گدشته</div>
                                            <div className="balance">{spilitNumber(this.state.data.orders_count_before_month)}<small className="ml-1 mr-1">عدد</small></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <CashesServiceCharts id={this.state.id} f={this.state.from} t={this.state.to} />

                        <CashesServiceDetails  id={this.state.id} f={this.state.from} t={this.state.to} />

                    </React.Fragment>
            );
        } else {
            return <Redirect to={'/'}/>;
        }
    }
}

const mapStateToComponent = state => {
    return {
        user: state.user.info,
        app: state.app,
    }
}

export default connect(mapStateToComponent)(CashesService);
