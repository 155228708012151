import React from 'react';
import http from "./../../../services/httpService";
import 'react-circular-progressbar/dist/styles.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import '@coreui/chartjs/scss/coreui-chartjs.scss';
import Emitter from '../../../services/Emitter';
import Squre from "../../../components/skelete/Squre";
import SkeleteLoading from "../../../components/SkeleteLoading";
import {spilitNumber} from "../../../services/helpers";

class Devices extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingForm: true,
            to: this.props.t,
            from: this.props.f,
            devices: {},
        }
    }

    getDevices=()=>{
        http.post('cashes/get/devices',{from:this.state.from,to:this.state.to})
            .then(response => {
                this.setState({
                    loadingForm: false,
                    devices:response.data,
                });
            });
    }

    componentDidMount() {
        Emitter.on('reloadData', () => this.getDevices());
    }

    componentWillUnmount() {
        Emitter.off('reloadData');
    }

    render() {

        return (
            this.state.loadingForm ?
                <SkeleteLoading>
                    <fieldset className={'mt-4'}>
                        <legend>اطلاعات دستگاه ها</legend>
                        <div className="row">
                            <div className="col-md-6"><Squre height={150}/></div>
                            <div className="col-md-6"><Squre height={150}/></div>

                        </div>
                    </fieldset>
                </SkeleteLoading>
                :
                <React.Fragment>
                    <fieldset className={'mt-4'}>
                        <legend>اطلاعات دستگاه ها</legend>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card card-body">
                                    <div className="card-title d-flex justify-content-between align-items-center">
                                        پلتفرم ها
                                    </div>
                                    <div className="items">
                                        {
                                            this.state.devices.request_from.map((rf) =>  (
                                                <div className="item">
                                                    <div className='value'><img height={20} src={'/assets/images/platforms/'+rf.request_from.toLowerCase()+'.png'} /></div>
                                                    <div>{spilitNumber(rf.count)}</div>
                                                    <div>{rf.request_from}</div>

                                                </div>
                                            ))
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card card-body">
                                    <div className="card-title d-flex justify-content-between align-items-center">
                                        دستگاه ها
                                    </div>
                                    <div className="items">
                                        {
                                            this.state.devices.agents.map((agent)=>(
                                                <div className="item">
                                                    <div className='value'><img height={20} src={'/assets/images/platforms/'+agent.agent.toLowerCase()+'.png'} /></div>
                                                    <div>{spilitNumber(agent.count)}</div>
                                                    <div>{agent.agent}</div>

                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </fieldset>
                </React.Fragment>

        )
    }

}

export default Devices;
