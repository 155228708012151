import React from 'react';
import Form from "./../../../ubold/commons/form";
import Joi from "joi-browser";
import auth from "../../../modules/auth/authService";
import {Redirect, Link} from "react-router-dom";
import Recaptcha from 'react-google-invisible-recaptcha';
import {siteKey, siteUrl} from '../../../config';
import routes from "../../../routes";
import RegisterMobileActive from "./tfa/RegisterMobileActive";
import ResetPassword from "./ResetPassword";

class ForgotPassword extends Form {

    constructor(props) {
        super(props);

        const search = window.location.search;
        var token = false;

        try {
            token = new URLSearchParams(search).get('token');
        } catch (e) {
        }

        this.state = {
            data: {email: ""},
            errors: {},
            loading: false,
            senEmail: false,
            maxNumberLength: 11,
            token: token,
            changePassword: false,
            redirect:false

        };
        this.schema = {
            email: Joi.string()
                .required()
                .label("شماره همراه")
        };
    }


    doSubmit = async () => {
        this.recaptcha.execute().then(res => {
            this.toSubmit(res);
        }).catch(res => {
            window.location = window.location.href;
        })
    }

    toSubmit = async (token) => {
        try {
            const {data} = this.state;
            data['g-recaptcha-response'] = token;
            this.setState({data});
            var res = await auth.forgotPass(data);
            this.toggle();
            this.setState({token: res.token});

            window.history.pushState("state", "title", routes.forgot_password + "?token=" + res.token);

        } catch (ex) {
            this.toggle();
            this.recaptcha.reset();
        }
    }

    onResolved = () => {
        const {data} = this.state;
        data['g-recaptcha-response'] = this.recaptcha.getResponse();
        this.setState({data});
    }

    changeNumner = ({currentTarget: input}) => {
        if (input.value.length > this.state.maxNumberLength)
            return false;

        this.setState({
            invalidNumber: !(input.value.length === this.state.maxNumberLength)
        });
    }

    callbackOtpCde = (res) => {
        this.setState({
            changePassword: res.b,
            token: res.token
        })
    }

    callbackToken=(token)=>{
        this.setState({
            token: token
        })
    }

    render() {
        if (auth.getCurrentUser()) return <Redirect to="/"/>;

        return (
            <React.Fragment>

                {this.state.token && !this.state.changePassword ?
                    <div className='col-lg-5 col-md-6'>
                        <div className="card">
                            <RegisterMobileActive callbacktoken={(token)=>this.callbackToken(token)} redirecturl={routes.forgot_password} token={this.state.token}
                                                  tokentype={'password'} callback={(res) => this.callbackOtpCde(res)}/>
                        </div>
                    </div>
                    :
                    (
                        this.state.changePassword ?
                            <ResetPassword token={this.state.token} b={this.state.changePassword} />
                            :
                            <div className='col-lg-5 col-md-6'>
                                <div className="col-12 ">
                                    <div className="mb-3">
                                        <a href={siteUrl}>
                                            <img className='d-block m-auto logo' height={180} width={180} alt={'cafearz'}
                                                 src='/assets/images/logo/arm.svg'/>
                                        </a>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body auth-box ">
                                        <div className="body auth-box ">
                                            <div className="auth-title-box">
                                                <h1 className="auth-title"> بازیابی رمز عبور </h1>
                                                <div className="text-center auth-desc">
                                                    لطفا آدرس زیر را با آدرس مرورگر چک کنید
                                                </div>
                                                <div className="has-text-centered mb-4">
                                                    <div className="url ltr">
                                                        <div className="has-text-success">
                                                            <svg>
                                                                <use xlinkHref="/assets/images/sprite2.svg#lock"></use>
                                                            </svg>
                                                            https://
                                                        </div>
                                                        cafearz.com
                                                    </div>
                                                </div>
                                            </div>
                                            <form method="POST" onSubmit={this.handleSubmit}
                                                  className="form-horizontal dialog-form embed">

                                                <div className="form-group rtl-label">
                                                    {this.renderInput("email", "شماره همراه خود را وارد کنید", 'number', 'ltr', '', '', '', '', '09xx xxx xxxx', (e) => {
                                                        this.changeNumner(e)
                                                    }, {'maxLength': this.state.maxNumberLength})}
                                                </div>

                                                <div className="form-group">

                                                    {this.renderButton("ارسال درخواست", 'btn-block btn-primary')}

                                                </div>

                                                <div className="mt-2 d-flex justify-content-between align-items-center">
                                                    <Link className=" font-12 recover-pass text-t" to={routes.home}><i
                                                        className='fe-arrow-right ml-1'></i>
                                                        بازگشت </Link>
                                                </div>


                                                <Recaptcha
                                                    ref={ref => this.recaptcha = ref}
                                                    sitekey={siteKey}
                                                    onResolved={this.onResolved}/>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    )


                }


            </React.Fragment>
        );
    }

}


export default ForgotPassword;

