import React from 'react';
import {connect} from "react-redux";
import 'react-circular-progressbar/dist/styles.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import '@coreui/chartjs/scss/coreui-chartjs.scss';
import moment from "jalali-moment";
import {updatePageTitle} from "../../actions/breadcrumbs";
import RangePicker from "../../components/RangePicker";
import http from "../../services/httpService";
import Emitter from "../../services/Emitter";
import {Redirect} from "react-router-dom";
import FinanceLoading from "../../components/loadings/FinanceLoading";
import {spilitNumber} from "../../services/helpers";
import OrderItemProfit from "./partials/OrderItemProfit";
import Pagination from "../../ubold/commons/pagination";
import SkeleteLoading from "../../components/SkeleteLoading";
import Squre from "../../components/skelete/Squre";

const types = {
    PAYPAL: 'پی پال',
    WEBMONEY: 'وب مانی',
    VISA_MASTER: 'ویزا و مسترکارت',
    PERFECTMONEY: 'پرفکت مانی',
    PAYEER: 'پایر (PAYEER)',
    SKRILL: 'اسکریل',
    GIFTCARDS: 'گیفت کارت',
    PAYMENT: 'پرداخت های بین المللی',
    VIP: ' خدمات ویژه',
    TETER: 'تتر',
    CRYPT: 'کریپتو',
}

class OrderSortProfit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingForm: true,
            orders:{},
            total : 1,
            last_page : 1,
            currentPage:1,
            type:'',
            max:{
                title:'ندارد',
                total:0,
                profit:0,
                id:0
            },
            min:{
                title:'ندارد',
                total:0,
                profit:0,
                id:0
            },
            to: moment().locale('fa').format('YYYY/M/D'),
            from: moment().locale('fa').format('YYYY/M/D'),

        }

        this.props.dispatch(updatePageTitle(
            'مرتب سازی سفارشات بر اساس سود',
            {},
            this.handleDispatchHtml()
        ));
    }

    applyDate=(date)=>{
        this.setState({
            to: date.endDate,
            from: date.startDate,
        },()=>{
            this.reloadData()
        });


    }

    reloadData=()=>{
        this.setState({
            loadingForm:true
        });

        this.props.dispatch(updatePageTitle(
            'مرتب سازی سفارشات بر اساس سود',
            {},
            this.handleDispatchHtml()
        ));

        this.getData();
    }

    handleDispatchHtml =()=>{
        return (
            <>
               <div className={'d-flex'}>
                   <select style={{    height: '31px'}} onChange={this.changeType} className={'ml-2 form-control'}>
                           <option value="">همه</option>
                           <option value="PAYPAL">پی پال  </option>
                           <option value="WEBMONEY">وب مانی</option>
                           <option value="VISA_MASTER"> ویزا و مسترکارت </option>
                           <option value="PERFECTMONEY">پرفکت مانی  </option>
                           <option value="PAYEER"> پایر </option>
                           <option value="SKRILL"> اسکریل </option>
                           <option value="GIFTCARDS"> گیفت کارت </option>
                           <option value="PAYMENT"> پرداخت های بین المللی </option>
                           <option value="VIP">خدمات ویژه  </option>
                           <option value="TETER"> تتر </option>
                           <option value="CRYPT"> کریپتو </option>
                   </select>
                   <RangePicker start={this.state.from}  end={this.state.to} onApply={date=>this.applyDate(date)} />

               </div>
            </>
        )
    }

    componentDidMount() {
        this.getData();
    }

    changeType =({currentTarget:input})=>{
        this.setState({type:input.value});
        this.getData(input.value);
    }

    handlePageChange = page => {
        if (page === 'next')
            this.setState({currentPage: this.state.currentPage+1},()=> this.getData());
        else if (page === 'prev')
            this.setState({currentPage: this.state.currentPage-1},()=> this.getData());
        else
            this.setState({currentPage: page},()=> this.getData());
    };


    getData=(type='')=>{
        http.post('orders/get/sort-by-profit?page='+this.state.currentPage,{from:this.state.from,to:this.state.to,type:(type?type:this.state.type)})
            .then(response => {
                this.setState({
                    loadingForm: false,
                    orders:response.data.orders.data,
                    total : response.data.orders.total,
                    last_page : response.data.orders.last_page,
                    max:response.data.max?response.data.max:this.state.max,
                    min:response.data.min?response.data.min:this.state.min
                });

                Emitter.emit('reloadData');
            });
    }

    render() {
        const user = this.props.user;
        const rules = user.rules;
        const permissions = user.permissions;
        const {last_page, currentPage,orders } = this.state;

        if(rules.includes('superadmin') || rules.includes('finance')|| permissions.includes('admin_report.finance')){
            return (
                this.state.loadingForm ?
                    <SkeleteLoading>
                        <fieldset >
                            <legend>بیشترین و کمترین</legend>
                            <div className="row">
                                <div className="col-md-6"><Squre height={100}/></div>
                                <div className="col-md-6"><Squre height={100}/></div>

                            </div>
                        </fieldset>
                    </SkeleteLoading>
                    :
                    <React.Fragment>
                        <fieldset>
                            <legend>بیشترین و کمترین</legend>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card card-body dashboard-rr medium">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <div className="card-title">{this.state.max.title}</div>
                                                <div className="balance">{spilitNumber(this.state.max.total)}<small className="ml-1 mr-1">تومان</small></div>
                                                <div className="count text-success">سود :
                                                    {spilitNumber(this.state.max.profit)}<small className="ml-1 mr-1">تومان</small>
                                                </div>
                                                <small className="count ">شماره سفارش :
                                                    {(this.state.max.id)}<small className="ml-1 mr-1"></small>
                                                </small>
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none" stroke="green"
                                                     stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                     className="feather feather-arrow-up">
                                                    <line x1="12" y1="19" x2="12" y2="5"></line>
                                                    <polyline points="5 12 12 5 19 12"></polyline>
                                                </svg>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div  className="card card-body dashboard-rr medium">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <div className="card-title">{this.state.min.title}</div>
                                                <div className="balance">{spilitNumber(this.state.min.total)}<small className="ml-1 mr-1">تومان</small></div>
                                                <div className={"count "+(this.state.min.profit>0?"text-success":"text-danger")}>سود :
                                                    {spilitNumber(this.state.min.profit)}<small className="ml-1 mr-1">تومان</small>
                                                </div>
                                                <small className="count ">شماره سفارش :
                                                    {(this.state.min.id)}<small className="ml-1 mr-1"></small>
                                                </small>
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none" stroke="#f00" stroke-linejoin="round"
                                                     className="feather feather-arrow-down">
                                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                                    <polyline points="19 12 12 19 5 12"></polyline>
                                                </svg>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <div className="mt-3 mb-3">
                            {
                                orders.length >0 &&  orders.map(order=>(
                                    <div key={order.id} className='card card-body card-table'>
                                        <OrderItemProfit
                                            order={order}
                                        />
                                    </div>
                                ))
                            }
                        </div>

                        <Pagination
                            total={last_page}
                            currentPage={currentPage}
                            onPageChange={this.handlePageChange}
                        />


                        {/*<OrdersCharts f={this.state.from} t={this.state.to}/>*/}

                        {/*<CostAndProfit f={this.state.from} t={this.state.to}/>*/}
                        {/*<Devices f={this.state.from} t={this.state.to}/>*/}

                    </React.Fragment>
            );
        } else {
            return <Redirect to={'/'}/>;
        }
    }
}

const mapStateToComponent = state => {
    return {
        user: state.user.info,
        app: state.app,
    }
}

export default connect(mapStateToComponent)(OrderSortProfit);
