import React from 'react';
import {updatePageTitle} from "../../../actions/breadcrumbs";
import {connect} from "react-redux";
import http from "./../../../services/httpService";
import 'react-circular-progressbar/dist/styles.css';
import DashboardLoading from "../../../components/loadings/DashboardLoading";

import "bootstrap-daterangepicker/daterangepicker.css";
import '@coreui/chartjs/scss/coreui-chartjs.scss';
import RangePicker from "../../../components/RangePicker";
import moment from 'jalali-moment';
import {spilitNumber} from "../../../services/helpers";
import Emitter from './../../../services/Emitter';
import Userdata from "./../../dashboard/superadmin/UserData";
import OrderData from "./../../dashboard/superadmin/OrderData";
import CashData from "./../../dashboard/superadmin/CashData";


class SuperAdmin extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingForm: true,
            to: moment().locale('fa').format('YYYY/M/D'),
            from: moment().locale('fa').format('YYYY/M/D'),
            userCount:0,
            cashCount:0,
            orderCount:0,
            userChart:false,
            orderChart:false,
            cashChart:false,

        }

        this.props.dispatch(updatePageTitle(
            'داشبورد',
            {},
            this.handleDispatchHtml()
        ));
    }

    handleDispatchHtml =()=>{
        return (
            <>
                <RangePicker start={this.state.from}  end={this.state.to} onApply={date=>this.applyDate(date)} />
            </>
        )
    }

    componentDidMount() {
        this.getData();
    }

    getData=()=>{
        http.post('dashboard/get/data',{from:this.state.from,to:this.state.to})
            .then(response => {
                this.setState({
                    loadingForm: false,
                    userCount:response.data.userCount,
                    orderCount:response.data.orderCount,
                    cashCount:response.data.cashCount,
                });

                Emitter.emit('reloadData');
            });
    }

    applyDate=(date)=>{
        this.setState({
            to: date.endDate,
            from: date.startDate,
        },()=>{
            this.reloadData()
        });


    }

    reloadData=()=>{
        this.setState({
            loadingForm:true
        });

        this.props.dispatch(updatePageTitle(
            'داشبورد',
            {},
            this.handleDispatchHtml()
        ));

        this.getData();
    }

    render() {

        return (
            this.state.loadingForm ?
                <DashboardLoading/>
                :
                <React.Fragment>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="card card-body dashboard-rr">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <div className="card-title">ثبت نام</div>
                                        <div className="balance">{spilitNumber(this.state.userCount)}<small className="ml-1 mr-1">نفر</small></div>
                                    </div>
                                    <div>
                                        <svg  width="141" height="141" viewBox="0 0 141 141" fill="#0bc61b" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M70.1332 64.289C74.7568 64.289 79.2767 62.9179 83.1211 60.3491C86.9656 57.7804 89.962 54.1293 91.7314 49.8575C93.5008 45.5858 93.9638 40.8853 93.0617 36.3505C92.1597 31.8156 89.9332 27.6501 86.6637 24.3806C83.3943 21.1112 79.2288 18.8847 74.6939 17.9827C70.1591 17.0806 65.4586 17.5436 61.1869 19.313C56.9151 21.0824 53.264 24.0788 50.6952 27.9232C48.1265 31.7677 46.7554 36.2875 46.7554 40.9112C46.7554 47.1114 49.2184 53.0576 53.6026 57.4418C57.9867 61.826 63.933 64.289 70.1332 64.289Z" />
                                            <path d="M105.2 122.733C106.75 122.733 108.237 122.118 109.333 121.022C110.429 119.926 111.044 118.439 111.044 116.889C111.044 106.039 106.734 95.6327 99.0618 87.9604C91.3895 80.2881 80.9836 75.9778 70.1333 75.9778C59.283 75.9778 48.8771 80.2881 41.2048 87.9604C33.5324 95.6327 29.2222 106.039 29.2222 116.889C29.2222 118.439 29.8379 119.926 30.934 121.022C32.03 122.118 33.5166 122.733 35.0666 122.733H105.2Z" />
                                        </svg>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div  className="card card-body dashboard-rr">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <div className="card-title">سفارش</div>
                                        <div className="balance">{spilitNumber (this.state.orderCount)}<small className="ml-1 mr-1">عدد</small></div>
                                    </div>
                                    <div>
                                        <svg width="191" height="191" viewBox="0 0 191 191" fill="#ff117b" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M167.281 55.5496C165.91 53.1739 163.947 51.194 161.582 49.8033C159.218 48.4125 156.534 47.6582 153.791 47.6141H52.2159L47.6133 29.6797C47.1483 27.9485 46.1108 26.4257 44.6698 25.3594C43.2289 24.293 41.4693 23.746 39.6778 23.8074H23.8066C21.702 23.8074 19.6836 24.6435 18.1954 26.1317C16.7072 27.6199 15.8711 29.6383 15.8711 31.7429C15.8711 33.8476 16.7072 35.866 18.1954 37.3542C19.6836 38.8424 21.702 39.6785 23.8066 39.6785H33.6467L55.5489 121.097C56.0139 122.829 57.0514 124.351 58.4923 125.418C59.9333 126.484 61.6929 127.031 63.4844 126.97H134.904C136.37 126.965 137.805 126.555 139.052 125.785C140.299 125.014 141.308 123.914 141.967 122.605L167.996 70.5478C169.124 68.1831 169.649 65.5759 169.524 62.9589C169.4 60.3419 168.629 57.7964 167.281 55.5496V55.5496Z" />
                                            <path d="M59.5166 166.647C66.0906 166.647 71.4199 161.317 71.4199 154.743C71.4199 148.169 66.0906 142.84 59.5166 142.84C52.9426 142.84 47.6133 148.169 47.6133 154.743C47.6133 161.317 52.9426 166.647 59.5166 166.647Z" />
                                            <path d="M138.872 166.647C145.446 166.647 150.775 161.317 150.775 154.743C150.775 148.169 145.446 142.84 138.872 142.84C132.298 142.84 126.969 148.169 126.969 154.743C126.969 161.317 132.298 166.647 138.872 166.647Z" />
                                        </svg>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card card-body dashboard-rr">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <div className="card-title">نقد درآمد</div>
                                        <div className="balance">{spilitNumber(this.state.cashCount)}<small className="ml-1 mr-1">عدد</small></div>
                                    </div>
                                    <div>
                                        <svg width="191" height="191" viewBox="0 0 191 191" fill="#ff9e06" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M89.275 93.8784L23.8066 66.342V127.684C23.8411 130.242 24.6066 132.737 26.0127 134.874C27.4187 137.011 29.4068 138.702 31.7422 139.746L88.7988 166.647H89.275V93.8784Z" />
                                            <path d="M95.2283 83.3234L162.76 54.9934C161.623 53.7307 160.248 52.7058 158.713 51.9779L101.577 25.2351C99.5922 24.2958 97.424 23.8086 95.2283 23.8086C93.0327 23.8086 90.8645 24.2958 88.8799 25.2351L31.7439 51.9779C30.2089 52.7058 28.8333 53.7307 27.6968 54.9934L95.2283 83.3234Z" />
                                            <path d="M101.178 93.8784V166.647H101.575L158.711 139.746C161.034 138.707 163.014 137.029 164.419 134.907C165.825 132.786 166.597 130.308 166.647 127.763V66.342L101.178 93.8784Z" />
                                        </svg>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <Userdata f={this.state.from} t={this.state.to}/>
                    <OrderData f={this.state.from} t={this.state.to}/>
                    <CashData f={this.state.from} t={this.state.to}/>

                </React.Fragment>

        )
    }

}

const mapStateToComponent = state => {
    return {
        user: state.user,
        app: state.app,
    }
}

export default connect(mapStateToComponent)(SuperAdmin);
