import React from 'react';
import Form from "./../../../ubold/commons/form";
import Joi from "joi-browser";
import auth from "../../../modules/auth/authService";
import {Link, Redirect} from "react-router-dom";
import Recaptcha from 'react-google-invisible-recaptcha';
import {siteKey, siteUrl} from '../../../config';
import routes from "../../../routes";

class Login extends Form {

    constructor(props) {
        super(props);
        this.state = {
            data: {email: "", 'g-recaptcha-response': "", password: ''},
            errors: {},
            maxNumberLength: 11,
            invalidNumber: true,
            loading: false,
            redirect: false,
            register_component: false,
            token: false,


        };

        this.schema = {
            email: Joi.number()
                .required()
                .label(" شماره همراه"),
        };
    }

    resetForm = () => {
        const data = this.state.data;
        data['email'] = '';

        this.setState({
            token: '', data, invalidNumber: true
        })
    }


    doSubmit = async () => {
        this.recaptcha.execute().then(res => {
            this.toSubmit(res);
        }).catch(res => {
            window.location = window.location.href;
        })
    };

    toSubmit = async (token) => {
        try {
            const {data} = this.state;
            data['g-recaptcha-response'] = token;
            this.setState({data});

            if (this.state.token) {
                const res = await auth.loginPassword(data);

                if (!res.login) {
                    this.setState({redirect: res.url});
                } else
                    window.location = routes.home;

            } else {
                const res = await auth.login(data);
                this.toggle();

                if (res.register === 'ok') {

                    this.setState({redirect: routes.register + '?token=' + res.token});
                }

                if (res.token) {
                    const {data}=this.state;
                    data['_tk']=res.token;

                    this.setState({token: res.token,data});
                    this.schema = {
                        password: Joi.string()
                            .required()
                            .label("رمز عبور"),
                    };
                }
            }


        } catch (ex) {
            this.toggle();
            this.recaptcha.reset();
        }
    }

    onResolved = () => {
        const {data} = this.state;
        data['g-recaptcha-response'] = this.recaptcha.getResponse();
        this.setState({data});
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }

        return (
            <div className='col-lg-5 col-md-6'>
                <div className="col-12 ">
                    <div className="mb-3">
                        <a href={siteUrl}>
                            <img className='d-block m-auto logo' height={180} width={180} alt={'cafearz'}
                                 src='/assets/images/logo/arm.svg'/>
                        </a>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body auth-box ">
                        <div className="auth-title-box">
                            <h1 className="auth-title">پنل گزارش کافه ارز</h1>
                        </div>
                        <div className="auth-title-box mb-4">
                            <div className='help text-center mb-2'> برای <strong
                                className='text-primary'>ورود </strong>  یا <strong className='text-primary'> ثبت
                                نام</strong> شماره همراه خود را وارد کنید
                            </div>
                        </div>

                        <form method="POST" onSubmit={this.handleSubmit}
                              className="form-horizontal dialog-form embed">

                            <div className="form-group rtl-label center-label mt-4">
                                {this.renderInput("email", "", 'number', 'ltr', '', '', '', '', '09xx xxx xxxx', (e) => {
                                    this.changeNumner(e)
                                }, {
                                    'maxLength': this.state.maxNumberLength,
                                    'disabled': this.state.token
                                })}
                            </div>

                            {
                                this.state.token ?
                                    <div className="form-group rtl-label ">
                                        {this.renderPassword("password", "", '', '', 'رمز عبور')}
                                    </div>
                                    : ""
                            }

                            <div className="d-flex justify-content-between align-items-center mt-4 text-white">آیا رمز عبور
                                خود را فراموش کرده‌اید؟
                                <Link href='#' to={routes.forgot_password} className="text-t mr-2">بازیابی رمز
                                    عبور</Link>
                            </div>

                            <div className="form-group mt-4">
                                {this.renderButton("ورود به سایت", 'btn-block btn-primary ', null, '')}
                            </div>


                            <Recaptcha
                                ref={ref => this.recaptcha = ref}
                                sitekey={siteKey}
                                onResolved={this.onResolved}/>

                        </form>
                    </div>
                </div>
            </div>
        );
    }

}


export default Login;

