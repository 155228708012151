import React from 'react';
import {connect} from "react-redux";
import 'react-circular-progressbar/dist/styles.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import '@coreui/chartjs/scss/coreui-chartjs.scss';
import moment from "jalali-moment";
import {updatePageTitle} from "../../actions/breadcrumbs";
import RangePicker from "../../components/RangePicker";
import http from "../../services/httpService";
import Emitter from "../../services/Emitter";
import {Redirect} from "react-router-dom";
import FinanceLoading from "../../components/loadings/FinanceLoading";
import {spilitNumber} from "../../services/helpers";
import CostAndProfit from "./partials/CostAndProfit";
import Devices from "./partials/Devices";
import TransactionsCharts from "./partials/TransactionsCharts";

class FinanceDashboard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingForm: true,
            transactions:{},
            to: moment().locale('fa').format('YYYY/M/D'),
            from: moment().locale('fa').format('YYYY/M/D'),
        }

        this.props.dispatch(updatePageTitle(
            'داشبورد مالی',
            {},
            this.handleDispatchHtml()
        ));
    }

    applyDate=(date)=>{
        this.setState({
            to: date.endDate,
            from: date.startDate,
        },()=>{
            this.reloadData()
        });


    }

    reloadData=()=>{
        this.setState({
            loadingForm:true
        });

        this.props.dispatch(updatePageTitle(
            'داشبورد مالی',
            {},
            this.handleDispatchHtml()
        ));

        this.getData();
    }

    handleDispatchHtml =()=>{
        return (
            <>
                <RangePicker start={this.state.from}  end={this.state.to} onApply={date=>this.applyDate(date)} />
            </>
        )
    }

    componentDidMount() {
        this.getData();
    }

    getData=()=>{
        http.post('finance/get/data',{from:this.state.from,to:this.state.to})
            .then(response => {
                this.setState({
                    loadingForm: false,
                    transactions:response.data,
                });

                Emitter.emit('reloadData');
            });
    }

    render() {
        const user = this.props.user;
        const rules = user.rules;
        const permissions = user.permissions;

        if(rules.includes('superadmin') || rules.includes('finance')|| permissions.includes('admin_report.finance')){
            return (
                this.state.loadingForm ?
                    <FinanceLoading/>
                    :
                    <React.Fragment>

                            <fieldset>
                                <legend>ورودی و خروجی ها</legend>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="card card-body dashboard-rr medium">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <div className="card-title">تراکنش های ورودی</div>
                                                    <div className="balance">{spilitNumber(this.state.transactions.all.total)}<small className="ml-1 mr-1">تومان</small></div>
                                                    <div className="count">{spilitNumber(this.state.transactions.all.count)}<small className="ml-1 mr-1">عدد</small></div>
                                                </div>
                                                <div>
                                                    <svg stroke="#17a502" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none" stroke-width="2"
                                                         stroke-linecap="round" stroke-linejoin="round"
                                                         className="feather feather-credit-card">
                                                        <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                                                        <line x1="1" y1="10" x2="23" y2="10"></line>
                                                    </svg>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div  className="card card-body dashboard-rr medium">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <div className="card-title">درگاه</div>
                                                    <div className="balance">{spilitNumber(this.state.transactions.gateway.total)}<small className="ml-1 mr-1">تومان</small></div>
                                                    <div className="count">{spilitNumber(this.state.transactions.gateway.count)}<small className="ml-1 mr-1">عدد</small></div>                                        </div>
                                                <div>
                                                    <svg stroke="#0064ff" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none"  stroke-width="2"
                                                         stroke-linecap="round" stroke-linejoin="round"
                                                         className="feather feather-briefcase">
                                                        <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
                                                        <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                                                    </svg>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="card card-body dashboard-rr medium">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <div className="card-title">کسر از موجودی</div>
                                                    <div className="balance">{spilitNumber(this.state.transactions.credit.total)}<small className="ml-1 mr-1">تومان</small></div>
                                                    <div className="count">{spilitNumber(this.state.transactions.credit.count)}<small className="ml-1 mr-1">عدد</small></div>                                        </div>
                                                <div>

                                                    <svg stroke="#ff9e00" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none"  stroke-width="2"
                                                         stroke-linecap="round" stroke-linejoin="round"
                                                         className="feather feather-share">
                                                        <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
                                                        <polyline points="16 6 12 2 8 6"></polyline>
                                                        <line x1="12" y1="2" x2="12" y2="15"></line>
                                                    </svg>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="card card-body dashboard-rr medium">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <div className="card-title"> تسویه</div>
                                                    <div className="balance">{spilitNumber(this.state.transactions.withdraw.total)}<small className="ml-1 mr-1">تومان</small></div>
                                                    <div className="count">{spilitNumber(this.state.transactions.withdraw.count)}<small className="ml-1 mr-1">عدد</small></div>                                        </div>
                                                <div>

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none"  stroke="#f00" stroke-width="2"
                                                         stroke-linecap="round" stroke-linejoin="round"
                                                         className="feather feather-share">
                                                        <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
                                                        <polyline points="16 6 12 2 8 6"></polyline>
                                                        <line x1="12" y1="2" x2="12" y2="15"></line>
                                                    </svg>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        <TransactionsCharts f={this.state.from} t={this.state.to}/>

                        <CostAndProfit f={this.state.from} t={this.state.to}/>
                        <Devices f={this.state.from} t={this.state.to}/>

                    </React.Fragment>
            );
        } else {
            return <Redirect to={'/'}/>;
        }
    }
}

const mapStateToComponent = state => {
    return {
        user: state.user.info,
        app: state.app,
    }
}

export default connect(mapStateToComponent)(FinanceDashboard);
